import forge_img from './forge_example.png'

import ItemSelector from '../components/ItemSelector.js'

import {useLocation} from 'react-router-dom'

const iframe = '<iframe height="828" style="width: 76%; float:left;" scrolling="no" title="fx." src="http://localhost:60521/" frameborder="no" allowtransparency="true" allowfullscreen="true"></iframe>'; 

function Iframe(props) {
    return (<div dangerouslySetInnerHTML={ {__html:  props.iframe?props.iframe:""}} />);
}


const Cellumancer = () => {
    const images = require.context('../../assets/images/gugs', true);
    let location = useLocation();
    let entityIndex = location.state?.entityIndex;

    const creatures = [
        {name: "Dave, The Defender", attack: 1, effect: "On entering the battlefield, dance", keyword: "dave", index: 0},
        {name: "Jim Daniels", attack: 2, effect: "On entering the battlefield, dance", keyword: "jim", index: 1},
        {name: "Three Images Merged", attack: 3, effect: "On entering the battlefield, dance", keyword: "tim", index: 2},
        {name: "A Regular Gug", attack: 4, effect: "On entering the battlefield, dance", keyword: "gug", index: 3},
        {name: "Jimmy Neutron", attack: 5, effect: "On entering the battlefield, dance", keyword: "jimmy", index: 4},
        {name: "Jimmy Neutron", attack: 5, effect: "On entering the battlefield, dance", keyword: "jimmy", index: 5},
        {name: "Jimmy Neutron", attack: 5, effect: "On entering the battlefield, dance", keyword: "jimmy", index: 6},
    ];

    return (
    <div>
        {/* <img src={forge_img} style={{width:'76%', float: 'left'}} /> */}
        <Iframe iframe={iframe} />
        <ItemSelector images={images}
                      creatures={creatures}
                      transferOptions={{source:'Cellumancer', target:'Project Spaceship'}}
                      entityIndex={entityIndex}/>
    </div>
  );
};

export default Cellumancer;