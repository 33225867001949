import CountUp from 'react-countup';

import './App.css';
import './Forge.css';

import React, { useRef, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import gug_logo from "./gug_logo_bordered.png";
import question_mark from "./question_mark.png";
import loading_image from "./loading.gif";
import steam_logo_large from "./steam_logo_large.png";
import error_image from "./error.png";
import steam_logo from "./steam_logo.png";
import discrod_logo from "./discord.png";
import link_copy from "./link_copy.png";
import facebook from "./facebook.png";
import whatsapp from "./whatsapp.png";
import twitter from "./twitter.png";
import reddit from "./reddit.png";

import wallpaper from "./wallpaper.png";
import grass from "./grass.png"

// import { GoogleLogin } from '@react-oauth/google';
// import { jwtDecode } from "jwt-decode";

import { useCookies } from 'react-cookie';

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import ReactPixel from 'react-facebook-pixel';

import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, RedditShareButton } from "react-share";

const options = {
  autoConfig: true
}

ReactPixel.init('1191426342060442', options);
ReactPixel.init('849220587351347', options);

const ENDPOINT_PATH = "https://gugappletbackend-production.up.railway.app/";
// const ENDPOINT_PATH = "http://127.0.0.1:5000/";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 30
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 16
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 6
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 6
  }
};

// From https://www.npmjs.com/package/react-multi-carousel
const Slideshow = (props) => {
  const ref= useRef();
  const [currentSlide, setCurrentSlide] = useState(20);
  const [reveal, setReveal] = useState(0);
  const [loaded, setLoaded] = useState(false);

  const offset = props.isMobile ? (responsive['mobile']['items'] / 2) : responsive['desktop']['items'] / 2;

  useEffect(() => {
    var splitPoint = null;
    if (currentSlide == 40) {
      splitPoint = 20 + offset - 1;
    } else {
      splitPoint = currentSlide + offset - 1;
    }
    var ul = ref.current.children[0].children[0];
    for (var i in ul.children) {
      var el = ul.children[i];
      if (el.style == undefined) continue;
      if (el.dataset["index"] > splitPoint) {
        el.children[0].className = "customShadow";
      } else if (el.dataset["index"] > splitPoint - reveal) {
        el.children[0].className = "";
      } else if (el.dataset["index"] <= splitPoint) {
        el.children[0].className = "hide";
      }
    }
    setLoaded(true);
  });

  return (
    <div ref={ref}>
      <Carousel
        responsive={responsive}
        swipeable={false}
        draggable={false}
        autoPlay={true}
        infinite={true}
        centerMode={false}
        transitionDuration={500}
        autoPlaySpeed={2000}
        removeArrowOnDeviceType={["desktop", "mobile", "tablet"]}
        itemClass="carousel-item-padding-40-px"
        afterChange={(previousSlide, { currentSlide, onMove }) => {
          setCurrentSlide(currentSlide);
          setReveal(reveal + 1);
        }}
    >
        {props.data.map((item, index) => (
          <div>
            <div style={{display: loaded ? "block" : "none"}}>
              <img id={index} src={item} style={{width: "3.5em", zIndex: "-1", position: "relative"}}/>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
}

const EffectVisualiser = (props) => {
  const raw_effect = props.raw_effect;
  if (props.display_raw) return raw_effect;
  
  var parseEffects = (rawEffectData) => {
      const effects = [];
      // Pattern to match each effect part based on the given format.
      const pattern = /\[(?<event>[\w]+)(\([Tt]his\)|\([Aa]ny\))?\]\s*(→|->|--)\s*(?:\((?<name>[^)]+)\))?\[(?<content>[^\]]+)\]/g;
      const matches = rawEffectData.matchAll(pattern);
      for (const match of matches) {
        const effect = {
            EventName: match.groups.event,
            Name: match.groups.name || null,
            Content: match.groups.content
        };
        effects.push(effect);
      }

      return effects;
  }

  let effects = parseEffects(raw_effect);

  return (
    <div>
      {effects.map((effect) => (
      <div>
        <div style={{fontWeight: "bolder"}}>
          {effect.EventName.replace("Event", "").replace("Phase", "").replace("Effect", "")}
        </div>
        <div>
          {effect.Content.replace("creature", "gug")}
        </div>
      </div>
      ))}
    </div>
  );
}

const DynamicCounter = (props) => {
  const [count, setCount] = useState(0);
  const [prevCount, setPrevCount] = useState(0);

  useEffect(() => {
    fetch(ENDPOINT_PATH + "get_gugs_count_new")
    .then((res) => res.text())
    .then((value) => {
      setPrevCount(count);
      setCount(value);
    });

    const interval = setInterval(
      () => {
        fetch(ENDPOINT_PATH + "get_gugs_count_new")
        .then((res) => res.text())
        .then((value) => {
          setPrevCount(count);
          setCount(value);
      });
    }, 5000);

    return () => clearInterval(interval);
  }, [count, prevCount]);

  return (
    <div>
      <CountUp start={prevCount} end={count} /> Gugs Made
    </div>
  )
}

const GUGLibrary = (props) => {
  // const [cookies, setCookie, removeCookie] = useCookies(['login-credential']);
  const [cookies, setCookie, removeCookie] = useCookies(['collection'], {doNotUpdate: true});

  const [data, setData] = useState();
  const [collection, setCollection] = useState([]);
  const [new_gug, setNewGug] = useState({});
  const [prompt, setPrompt] = useState('');
  const [generating, setGenerating] = useState("never");
  const [shareURL, setShareURL] = useState("");
  const [loadingTip, setLoadingTip] = useState("");

  const [showCopy, setShowCopy] = useState(false);

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }

  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  const isMobile = width <= 1024;

  const loadingTips = [
    "Join the GUG Discord for the latest updates",
    "Wishlist GUG on Steam",
    "Share your gug with your friends!",
    "GUG uses machine learning to modify its own code!",
    "There are infinite gugs, though we haven't counted",
    "GUG stands for Generatively Unsupervised GUG",
    "In GUG, you assemble gugs to counter the toxic wilds' threats",
    "Gugs can't tell left from right",
    "If you had a dollar for every time someone made a gug, you'd have at least 2 dollars",
    "There's a gug behind you, right now"
  ]
  
  useEffect(() => {
    fetch(ENDPOINT_PATH + "get_resized_gugs_subsample_new")
      .then((res) => res.json())
      .then((values) => {
        setData(values);
    });
  }, []);

  
  if (!cookies.collection) {
    setCookie("collection", []);
    return "";
  }

  if (cookies.collection.length != 0 && collection.length == 0) {
    setCollection(cookies.collection);
    setGenerating("never");
  }


  var handleClick = () => {
    if (generating == "yes") return;
    setGenerating("yes");
    setShareURL("");
    setLoadingTip(loadingTips[Math.floor(Math.random() * loadingTips.length)]);
    setNewGug({image_url: loading_image});
    fetch(ENDPOINT_PATH + 
      "generate_gug?keyword=" + prompt.replace(" ", "_") 
      // + "&credential=" + cookies.credential
    )
        .then((res) => res.json())
        .then((data) => {
          let path_to_poll = ENDPOINT_PATH + data['location'].substr(1);
          let apiTimeout = setTimeout(pollAPI, 1000);

          function pollAPI(){
            fetch(path_to_poll)
              .then((res) => res.json())
              .then((data) => {
                      if(data == ''){
                          // No data received, the result is still baking.
                          // Recreate a setTimeout API call which will be fired after 1 second.
                          apiTimeout = setTimeout(pollAPI, 1000);
                      } else {
                          // Result is ready.
                          if ('failure_reason' in data) {
                            setNewGug({image_url: error_image, effect: data['failure_reason'], name: data['failure'], display_raw: true});
                            setGenerating("no");
                            return;
                          }
                          if ('info_reason' in data) {
                            setNewGug({image_url: steam_logo_large, effect: data['info_reason'], name: data['info'], display_raw: true});
                            setGenerating("wait");
                            setTimeout(() => { setGenerating("no"); }, 5000);
                            return;
                          }
                          data['display_raw'] = false
                          data['image_url'] = data['image_url']
                          setNewGug(data);
                          setCookie("collection", collection.concat(data));
                          setCollection(collection.concat(data));
                          setGenerating("no");
                          setShareURL(`https://gug.gg/?keyword=${encodeURIComponent(data['prompt'])}`);

                          clearTimeout(apiTimeout);
                      }
              })
          }
        });
  };

  var onMouseOver = (item) => {
    if (generating == "yes") return;
    setNewGug(item);
  }

  // function responseMessage(response) {
  //   // const decoded = jwtDecode(response['credential']);
  //   // console.log(decoded['email']);
  //   setCookie('credential', response['credential']);
  // }
  
  if (!data) {
    return (
      <div style={{height: "100vh", margin: "auto", color: "#386256", fontWeight: "bold", fontSize: "2em", position: "relative"}}>
        <div style={{top: "50%", position: "relative", transform: "translateY(-50%)"}}>
          "Loading ..."
        </div>
      </div>
    );
  }

  if (Object.keys(new_gug).length == 0) setNewGug({image_url: question_mark});

  return (
    <div>
      <div style={{fontWeight: "bolder", color: "#386256"}}>
        <div style={{position: "absolute", width: "100%", backgroundColor: "#A4B88E", padding: "10px 0px 0px 0px", minHeight: "3.8em", borderBottom: "2px solid #386256", boxShadow: "0px 2px 5px black"}}>
          <Slideshow data={data} isMobile={isMobile} />
        </div>
        <img src={gug_logo} alt="GUG Logo" style={{height: "5em", zIndex: "1000", position: "relative"}}/>
        <div style={{position: "absolute", display: isMobile ? "none" : "block", width: "100%"}}>
          <a href={`https://discord.com/invite/VkbF6SdBta`} target='_blank'>
            <div style={{fontSize: "15px", color: "white", backgroundColor: "#5765F2", float: "right", width: "190px", marginRight: "50px", borderRadius: "20px", padding: "7px"}}>
              <div style={{height: "20px"}}>
                <div style={{float: "left", height: "100%", top: "50%", position: "relative", transform: "translateY(-50%)", padding: "0px 5px 0px 10px"}}>
                  Join the GUG Discord
                </div>
                <div style={{verticalAlign: "middle", display: "inline"}}>
                  <img src={discrod_logo} width="15px" />
                </div>
              </div>
            </div>
          </a>
        </div>
        <div style={{paddingBottom: "15px"}}>
          <div style={{margin: "auto", display: "inline-block", fontSize: "0.7em", color: "#386256", fontWeight: "bold"}}>
            <DynamicCounter />
          </div>
          <div style={{display: "inline-block", paddingTop: "5px", marginLeft: "25px"}}>
            <div style={{fontSize: "0.5em", fontWeight: "bolder", color: "#171D25", padding: "5px 0px 5px 0px", width: "100%"}}>
              The demo drops Oct 8th for free!
            </div>
            <a href={`https://store.steampowered.com/app/2824790/GUG/?utm_source=${props.utmSource + "_a"}&utm_campaign=${props.utmCampaign + "_a"}`} target='_blank' onClick={() => {ReactPixel.fbq("trackSingle", props.correctPixelId, 'Lead');}}>
              <div style={{fontSize: "15px", color: "white", backgroundColor: "#171D25", width: "205px", margin: "auto", borderRadius: "20px", padding: "7px"}}>
                <div style={{height: "20px"}}>
                  <div style={{float: "left", height: "100%", top: "50%", position: "relative", transform: "translateY(-50%)", padding: "0px 5px 0px 10px"}}>
                    Wishlist GUG on Steam
                  </div>
                  <div style={{verticalAlign: "middle", display: "inline"}}>
                    <img src={steam_logo} width="15px" />
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div style={{border: "2px solid #386256", margin: "auto", padding: "10px 0px", backgroundColor: "#A4B88E", width: "15em", paddingBottom: "23px"}}>
        <div style={{paddingBottom: "5px", fontSize: "1em"}}>
          <label for="lname" style={{fontSize: "0.7em", fontWeight: "bold", color: "#386256"}}>Type a brief prompt:</label>
        </div>
        <input type="text" className="prompt-input" id="lname" name="lname" placeholder="volcano dog..." maxLength={20} value={prompt} onInput={e => setPrompt(e.target.value)}/>
        <input type="submit" className="submit-button" disabled={generating == "yes" || generating == "wait"} value="Get GUG" onClick={handleClick}/>
      </div>
      <img src={grass} style={{width: "calc(15em + 4px)"}}/>
      <div style={{paddingBottom: "20px"}}>
        <div style={{width: "450px", margin: "auto", display: isMobile ? "none" : "inline-block"}}>
          <div style={{display: "inline-block", fontSize: "0.7em", color: "#386256", fontWeight: "bold", paddingRight: "10px"}}>
            Share this gug:
          </div>
          <button disabled={!shareURL} style={{fontSize: "15px", backgroundColor: shareURL ? "#0965FE" : "", width: "40px", margin: "auto", marginRight: "20px", borderRadius: "20px", padding: "7px", display: "inline-block"}}>
            <FacebookShareButton url={shareURL ? shareURL : "https://gug.gg"} disabled={!shareURL} >
              <div style={{height: "20px"}}>
                <div style={{verticalAlign: "middle", display: "inline"}}>
                  <img src={facebook} width="20px" />
                </div>
              </div>
            </FacebookShareButton>
          </button>
          <button disabled={!shareURL} style={{fontSize: "15px", backgroundColor: shareURL ? "#00ACED" : "", width: "40px", margin: "auto", marginRight: "20px", borderRadius: "20px", padding: "7px", display: "inline-block"}}>
            <TwitterShareButton url={shareURL ? shareURL : "https://gug.gg"} disabled={!shareURL}>
              <div style={{height: "20px"}}>
                <div style={{verticalAlign: "middle", display: "inline"}}>
                  <img src={twitter} width="20px" />
                </div>
              </div>
            </TwitterShareButton>
          </button>
          <button disabled={!shareURL} style={{fontSize: "15px", backgroundColor: shareURL ? "#38D773" : "", width: "40px", margin: "auto", marginRight: "20px", borderRadius: "20px", padding: "7px", display: "inline-block"}}>
            <WhatsappShareButton url={shareURL ? shareURL : "https://gug.gg"} disabled={!shareURL}>
              <div style={{height: "20px"}}>
                <div style={{verticalAlign: "middle", display: "inline"}}>
                  <img src={whatsapp} width="20px" />
                </div>
              </div>
            </WhatsappShareButton>
          </button>
          <button disabled={!shareURL} style={{fontSize: "15px", backgroundColor: shareURL ? "#FF4500" : "", width: "40px", margin: "auto", marginRight: "20px", borderRadius: "20px", padding: "7px", display: "inline-block"}}>
            <RedditShareButton url={shareURL ? shareURL : "https://gug.gg"} disabled={!shareURL}>
              <div style={{height: "20px"}}>
                <div style={{verticalAlign: "middle", display: "inline"}}>
                  <img src={reddit} width="20px" />
                </div>
              </div>
            </RedditShareButton>
          </button>
          <div style={{display: "inline-block"}}>
            <button disabled={!shareURL} style={{fontSize: "15px", borderRadius: "30px", width: "40px", margin: "auto", padding: "7px"}} onClick={() => {navigator.clipboard.writeText(shareURL); setShowCopy(true); setTimeout(() => {setShowCopy(false);}, 2000)}}>
              <div style={{height: "20px"}}>
                <div style={{verticalAlign: "middle", display: "inline"}}>
                  <img src={link_copy} width="20px" />
                </div>
              </div>
            </button>
            <div style={{position: "absolute", margin: "5px", fontSize: "0.3em", color: "black", display: showCopy ? "block" : "none"}}>
              Copied!
            </div>
          </div>
        </div>
        <div style={{margin: "5px auto", position: "relative"}}>
          <img src={new_gug['image_url']} style={{width: "10em", marginTop: "15px", margin: "auto", display: "block"}}/>
        </div>
        <div style={{color: "#386256", fontWeight: "bold"}}>
          {
            generating == "yes" ? 
            <div>
              <div style={{marginBottom: "7px", fontSize: "1.1em"}}>
                While you wait
              </div>
              <div style={{"fontSize": "0.7em"}}>
                <div style={{maxWidth: "40ch", margin: "auto", backgroundColor: "#A4B88E", border: "2px solid #386256", height: "6em", lineHeight: "1em", padding: "5px"}}>
                  <div style={{position: "relative", margin: "auto", top: "50%", transform: "translateY(-50%)"}}>
                    {loadingTip}
                  </div>
                </div>
              </div>
            </div> 
            : generating == "never" ?
            <div>
              <div style={{marginBottom: "7px", fontSize: "1.1em"}}>
                Generate a gug above
              </div>
              <div style={{"fontSize": "0.7em"}}>
                <div style={{maxWidth: "40ch", margin: "auto", backgroundColor: "#A4B88E", border: "2px solid #386256", height: "6em", lineHeight: "1em", padding: "5px", overflow: "hidden"}}>
                  <div style={{position: "relative", margin: "auto", top: "50%", transform: "translateY(-50%)"}}>
                    This is where its effect will show
                  </div>
                </div>
              </div>
            </div> 
            :
            <div>
              <div style={{marginBottom: "7px", fontSize: "1.1em"}}>
                {new_gug['name']}
              </div>
              <div style={{"fontSize": "0.7em"}}>
                <div style={{maxWidth: "40ch", margin: "auto", backgroundColor: "#A4B88E", border: "2px solid #386256", height: "6em", lineHeight: "1em", overflow: "hidden", padding: "5px"}}>
                  <div style={{position: "relative", margin: "auto", top: "50%", transform: "translateY(-50%)"}}>
                    <EffectVisualiser raw_effect = {new_gug['effect']} display_raw={new_gug['display_raw']}/>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
        <div style={{width: "100%", margin: "auto", display: isMobile ? "inline-block" : "none"}}>
          <div style={{display: "block", fontSize: "1em", color: "#386256", fontWeight: "bold", margin: "20px"}}>
            Share this gug:
          </div>
          <button disabled={!shareURL} style={{fontSize: "15px", backgroundColor: shareURL ? "#0965FE" : "", width: "40px", margin: "auto", marginRight: "20px", borderRadius: "20px", padding: "7px", display: "inline-block"}}>
            <FacebookShareButton url={shareURL ? shareURL : "https://gug.gg"} disabled={!shareURL} >
              <div style={{height: "20px"}}>
                <div style={{verticalAlign: "middle", display: "inline"}}>
                  <img src={facebook} width="20px" />
                </div>
              </div>
            </FacebookShareButton>
          </button>
          <button disabled={!shareURL} style={{fontSize: "15px", backgroundColor: shareURL ? "#00ACED" : "", width: "40px", margin: "auto", marginRight: "20px", borderRadius: "20px", padding: "7px", display: "inline-block"}}>
            <TwitterShareButton url={shareURL ? shareURL : "https://gug.gg"} disabled={!shareURL}>
              <div style={{height: "20px"}}>
                <div style={{verticalAlign: "middle", display: "inline"}}>
                  <img src={twitter} width="20px" />
                </div>
              </div>
            </TwitterShareButton>
          </button>
          <button disabled={!shareURL} style={{fontSize: "15px", backgroundColor: shareURL ? "#38D773" : "", width: "40px", margin: "auto", marginRight: "20px", borderRadius: "20px", padding: "7px", display: "inline-block"}}>
            <WhatsappShareButton url={shareURL ? shareURL : "https://gug.gg"} disabled={!shareURL}>
              <div style={{height: "20px"}}>
                <div style={{verticalAlign: "middle", display: "inline"}}>
                  <img src={whatsapp} width="20px" />
                </div>
              </div>
            </WhatsappShareButton>
          </button>
          <button disabled={!shareURL} style={{fontSize: "15px", backgroundColor: shareURL ? "#FF4500" : "", width: "40px", margin: "auto", marginRight: "20px", borderRadius: "20px", padding: "7px", display: "inline-block"}}>
            <RedditShareButton url={shareURL ? shareURL : "https://gug.gg"} disabled={!shareURL}>
              <div style={{height: "20px"}}>
                <div style={{verticalAlign: "middle", display: "inline"}}>
                  <img src={reddit} width="20px" />
                </div>
              </div>
            </RedditShareButton>
          </button>
          <div style={{display: "inline-block"}}>
            <button disabled={!shareURL} style={{fontSize: "15px", borderRadius: "30px", width: "40px", margin: "auto", padding: "7px"}} onClick={() => {navigator.clipboard.writeText(shareURL); setShowCopy(true); setTimeout(() => {setShowCopy(false);}, 2000)}}>
              <div style={{height: "20px"}}>
                <div style={{verticalAlign: "middle", display: "inline"}}>
                  <img src={link_copy} width="20px" />
                </div>
              </div>
            </button>
            <div style={{position: "absolute", margin: "5px", fontSize: "0.3em", color: "black", display: showCopy ? "block" : "none"}}>
              Copied!
            </div>
          </div>
        </div>
        <div style={{display: isMobile ? "block" : "none", width: "100%"}}>
          <a href={`https://discord.com/invite/VkbF6SdBta`} target='_blank'>
            <div style={{fontSize: "15px", fontWeight: "bolder", color: "white", backgroundColor: "#5765F2", margin: "20px auto", width: "190px", borderRadius: "20px", padding: "7px"}}>
              <div style={{height: "20px"}}>
                <div style={{float: "left", height: "100%", top: "50%", position: "relative", transform: "translateY(-50%)", padding: "0px 5px 0px 10px"}}>
                  Join the GUG Discord
                </div>
                <div style={{verticalAlign: "middle", display: "inline"}}>
                  <img src={discrod_logo} width="15px" />
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
      {/* {generating == "never" ?  "" :
        <div style={{fontSize: "0.7em"}}> Your Collection (Click to view) </div>
      }
      {collection.map((item) => (
        <img src={item['image_url']} style={{width: "64px"}} onMouseOver={() => onMouseOver(item)}/>
      ))} */}
      
      {/* {cookies.credential ? "Logged In as " + jwtDecode(cookies.credential)['name'] :
      generating == "never" ?  "" :  
        <div style={{fontSize: "0.7em"}}>
          <div style={{maxWidth: "300px", display: "inline-block", height: "40px"}}>
            Want To Keep It?
          </div>
          <div style={{maxWidth: "300px", display: "inline-block", height: "40px"}}>
            <GoogleLogin onSuccess={responseMessage} />
          </div>
        </div>
      } */}
    </div>
  );
};


function Forge() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [name, setName] = useState("Loading");
  const [effect, setEffect] = useState("Loading");
  const [imageURL, setImageURL] = useState(question_mark);
  const [utmSource, setUTMSource] = useState("applet");
  const [utmCampaign, setUTMCampaign] = useState("top_banner");

  const correctPixelId = utmCampaign == "gcc" ? '1191426342060442' : '849220587351347';

  ReactPixel.fbq("trackSingle", correctPixelId, "pageView");

  useEffect (() => {
    const urlUtmCampaign = searchParams.get("utm_campaign");
    if (urlUtmCampaign != null && utmCampaign != urlUtmCampaign) {
      setUTMCampaign(urlUtmCampaign);
    }
    const urlUtmSource = searchParams.get("utm_source");
    if (urlUtmSource != null && urlUtmSource != utmSource) {
      setUTMSource(urlUtmSource);
    }
  });

  const keyword = searchParams.get("keyword");
  if (keyword != null) {
    Promise.all([
      fetch(ENDPOINT_PATH + `get_gug_name?keyword=${keyword}`).then(res => res.text()),
      fetch(ENDPOINT_PATH + `get_gug_effect?keyword=${keyword}`).then(res => res.text()),
      fetch(ENDPOINT_PATH + `get_gug_image_url_medium?keyword=${keyword}`).then(res => res.text())
    ])
    .then((results) => {
      setName(results[0]);
      setEffect(results[1]);
      setImageURL(results[2]);
    });

    const removeKeywordParam = () => {
      if (searchParams.has('keyword')) {
        searchParams.delete('keyword');
        setSearchParams(searchParams);
      }
    }

    return (
      <div className="App">
        <header className="App-header" style={{minHeight: "100vh", backgroundColor: `#F6E5D2`, display: "inherit", backgroundImage: `url(${wallpaper})`}}>
          <div style={{width:"100%", color: "#386256", height: "100%", height: "100vh", margin: "auto", alignContent: "center"}}>
            <div style={{backgroundColor: "rgba(255, 255, 255, 0.4)", padding: "30px", width: "16em", margin: "auto"}}>
              <div style={{fontWeight: "bold", marginBottom: "20px"}}>
                Here is the gug you clicked for!
              </div>
              <div style={{margin: "5px auto", position: "relative"}}>
                <img src={imageURL} style={{width: "10em", marginTop: "15px", margin: "auto", display: "block"}}/>
              </div>
              <div>
                <div style={{marginBottom: "7px", fontWeight: "bold", fontSize: "1.1em"}}>
                  {name}
                </div>
                <div style={{"fontSize": "0.7em", fontWeight: "bold"}}>
                  <div style={{maxWidth: "40ch", margin: "auto", backgroundColor: "#A4B88E", border: "2px solid #386256", height: "6em", lineHeight: "1em", overflow: "hidden", padding: "5px"}}>
                    <div style={{position: "relative", margin: "auto", top: "50%", transform: "translateY(-50%)"}}>
                      <EffectVisualiser raw_effect = {effect} display_raw={false}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button style={{fontSize: "0.7em", fontWeight: "bolder", margin: "20px", border: "5px solid #386256", color: "#386256", padding: "20px", backgroundColor: "#A4B88E"}} onClick={removeKeywordParam}>
              Click here to go to the gug maker!
            </button>
          </div>
        </header>
      </div>
    );
  }
  
  return (
    <div className="App">
      <header className="App-header" style={{minHeight: "100vh", backgroundColor: `#F6E5D2`, display: "inherit", backgroundImage: `url(${wallpaper})`}}>
        <div style={{width:"100%", height: "100%", position: "relative"}}>
          <GUGLibrary utmSource={utmSource} utmCampaign={utmCampaign} correctPixelId={correctPixelId}/>
        </div>
      </header>
    </div>
  );
}

export default Forge;